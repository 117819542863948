<template>
  <header class="shadow-sm">
    <nav class="container mx-auto p-4 flex justify-between text-4xl">
      <NuxtLink to="/" class="font-bold"
        >11 Hero <span class="text-sm">(pre-alpha)</span></NuxtLink
      >
      <ul class="flex gap-4">
        <li>
          <ClientOnly>
            <UButton
              :icon="
                isDark
                  ? 'i-heroicons-moon-20-solid'
                  : 'i-heroicons-sun-20-solid'
              "
              color="gray"
              variant="ghost"
              aria-label="Theme"
              @click="isDark = !isDark"
            />
            <template #fallback>
              <div class="w-8 h-8" />
            </template>
          </ClientOnly>
        </li>
        <li>
          <NuxtLink to="/" class="btn">Home</NuxtLink>
        </li>
        
        <li>
          <NuxtLink to="/matches" class="btn">Matches</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/players" class="btn">Players</NuxtLink>
        </li>
        <li>
          <NuxtLink to="https://solidslime.net/cloud" class="btn"
            >ETT Cloud Viewer</NuxtLink
          >
        </li>
        <li>
          <NuxtLink to="/upload" class="btn">Upload</NuxtLink>
        </li>
        <li v-if="!loggedIn">
          <NuxtLink to="/login" class="btn">Login</NuxtLink>
        </li>
        <li v-if="loggedIn">
          <NuxtLink to="/dashboard" class="btn">Dashboard</NuxtLink>
        </li>
        
      </ul>
    </nav>
  </header>
</template>

<script lang="ts" setup>
const { loggedIn, user } = useHeroAuth().value;
const colorMode = useColorMode();
const isDark = computed({
  get() {
    return colorMode.value === "dark";
  },
  set() {
    colorMode.preference = colorMode.value === "dark" ? "light" : "dark";
  },
});
</script>

<style scoped></style>
