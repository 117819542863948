<script lang="ts" setup></script>

<template>
  <div>
    <Header></Header>

    <!-- Output page content -->
    <div class="container mx-auto p-4">
      <slot />
    </div>
    <Footer></Footer>
  </div>
</template>

<style scoped>
.router-link-exact-active {
  color: #12b488;
}
</style>
