export default defineNuxtRouteMiddleware(async (to, from) => {
  // const siteTitle = "Your Site Title";
  // console.log(to.meta);
  // if (to.meta.title) {
  //   useHead({
  //     title: `${to.meta.title} | ${siteTitle}`,
  //   });
  // } else {
  //   useHead({
  //     title: siteTitle,
  //   });
  // }
});
