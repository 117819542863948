import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91id_93Pn7Q7gtBo7Meta } from "/app/pages/files/[id].vue?macro=true";
import { default as indexaxRBkpZK3hMeta } from "/app/pages/files/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as index62VVgTK5ZLMeta } from "/app/pages/matches/[id]/index.vue?macro=true";
import { default as pointsGonipn0KAGMeta } from "/app/pages/matches/[id]/points.vue?macro=true";
import { default as serviceDtYl2irVFKMeta } from "/app/pages/matches/[id]/service.vue?macro=true";
import { default as indexreavjmKP6OMeta } from "/app/pages/matches/index.vue?macro=true";
import { default as _91id_93Emaaq0veoMMeta } from "/app/pages/players/[id].vue?macro=true";
import { default as indexYLLnNOWxVjMeta } from "/app/pages/players/index.vue?macro=true";
import { default as _91id_93kVZKMJ3tbEMeta } from "/app/pages/products/[id].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as editbUooxexoBEMeta } from "/app/pages/profile/edit.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as _91id_93R8oVTxpoMMMeta } from "/app/pages/sandbox/[id].vue?macro=true";
import { default as indexHMczZc2BKkMeta } from "/app/pages/sandbox/index.vue?macro=true";
import { default as primevueXhNxl3l0TxMeta } from "/app/pages/sandbox/primevue.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as tokenHm95qqbX8gMeta } from "/app/pages/token.vue?macro=true";
import { default as uploadelBuHMr4HhMeta } from "/app/pages/upload.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: admin0eiku7Jj1aMeta?.name ?? "admin",
    path: admin0eiku7Jj1aMeta?.path ?? "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    alias: admin0eiku7Jj1aMeta?.alias || [],
    redirect: admin0eiku7Jj1aMeta?.redirect,
    component: () => import("/app/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: dashboardJu37u7k0toMeta?.name ?? "dashboard",
    path: dashboardJu37u7k0toMeta?.path ?? "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    alias: dashboardJu37u7k0toMeta?.alias || [],
    redirect: dashboardJu37u7k0toMeta?.redirect,
    component: () => import("/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Pn7Q7gtBo7Meta?.name ?? "files-id",
    path: _91id_93Pn7Q7gtBo7Meta?.path ?? "/files/:id()",
    meta: _91id_93Pn7Q7gtBo7Meta || {},
    alias: _91id_93Pn7Q7gtBo7Meta?.alias || [],
    redirect: _91id_93Pn7Q7gtBo7Meta?.redirect,
    component: () => import("/app/pages/files/[id].vue").then(m => m.default || m)
  },
  {
    name: indexaxRBkpZK3hMeta?.name ?? "files",
    path: indexaxRBkpZK3hMeta?.path ?? "/files",
    meta: indexaxRBkpZK3hMeta || {},
    alias: indexaxRBkpZK3hMeta?.alias || [],
    redirect: indexaxRBkpZK3hMeta?.redirect,
    component: () => import("/app/pages/files/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: index62VVgTK5ZLMeta?.name ?? "matches-id",
    path: index62VVgTK5ZLMeta?.path ?? "/matches/:id()",
    meta: index62VVgTK5ZLMeta || {},
    alias: index62VVgTK5ZLMeta?.alias || [],
    redirect: index62VVgTK5ZLMeta?.redirect,
    component: () => import("/app/pages/matches/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: pointsGonipn0KAGMeta?.name ?? "matches-id-points",
    path: pointsGonipn0KAGMeta?.path ?? "/matches/:id()/points",
    meta: pointsGonipn0KAGMeta || {},
    alias: pointsGonipn0KAGMeta?.alias || [],
    redirect: pointsGonipn0KAGMeta?.redirect,
    component: () => import("/app/pages/matches/[id]/points.vue").then(m => m.default || m)
  },
  {
    name: serviceDtYl2irVFKMeta?.name ?? "matches-id-service",
    path: serviceDtYl2irVFKMeta?.path ?? "/matches/:id()/service",
    meta: serviceDtYl2irVFKMeta || {},
    alias: serviceDtYl2irVFKMeta?.alias || [],
    redirect: serviceDtYl2irVFKMeta?.redirect,
    component: () => import("/app/pages/matches/[id]/service.vue").then(m => m.default || m)
  },
  {
    name: indexreavjmKP6OMeta?.name ?? "matches",
    path: indexreavjmKP6OMeta?.path ?? "/matches",
    meta: indexreavjmKP6OMeta || {},
    alias: indexreavjmKP6OMeta?.alias || [],
    redirect: indexreavjmKP6OMeta?.redirect,
    component: () => import("/app/pages/matches/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Emaaq0veoMMeta?.name ?? "players-id",
    path: _91id_93Emaaq0veoMMeta?.path ?? "/players/:id()",
    meta: _91id_93Emaaq0veoMMeta || {},
    alias: _91id_93Emaaq0veoMMeta?.alias || [],
    redirect: _91id_93Emaaq0veoMMeta?.redirect,
    component: () => import("/app/pages/players/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYLLnNOWxVjMeta?.name ?? "players",
    path: indexYLLnNOWxVjMeta?.path ?? "/players",
    meta: indexYLLnNOWxVjMeta || {},
    alias: indexYLLnNOWxVjMeta?.alias || [],
    redirect: indexYLLnNOWxVjMeta?.redirect,
    component: () => import("/app/pages/players/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kVZKMJ3tbEMeta?.name ?? "products-id",
    path: _91id_93kVZKMJ3tbEMeta?.path ?? "/products/:id()",
    meta: _91id_93kVZKMJ3tbEMeta || {},
    alias: _91id_93kVZKMJ3tbEMeta?.alias || [],
    redirect: _91id_93kVZKMJ3tbEMeta?.redirect,
    component: () => import("/app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: index18IXo8oN8QMeta?.name ?? "products",
    path: index18IXo8oN8QMeta?.path ?? "/products",
    meta: index18IXo8oN8QMeta || {},
    alias: index18IXo8oN8QMeta?.alias || [],
    redirect: index18IXo8oN8QMeta?.redirect,
    component: () => import("/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: editbUooxexoBEMeta?.name ?? "profile-edit",
    path: editbUooxexoBEMeta?.path ?? "/profile/edit",
    meta: editbUooxexoBEMeta || {},
    alias: editbUooxexoBEMeta?.alias || [],
    redirect: editbUooxexoBEMeta?.redirect,
    component: () => import("/app/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexaeouN7Kzh7Meta?.name ?? "profile",
    path: indexaeouN7Kzh7Meta?.path ?? "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    alias: indexaeouN7Kzh7Meta?.alias || [],
    redirect: indexaeouN7Kzh7Meta?.redirect,
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R8oVTxpoMMMeta?.name ?? "sandbox-id",
    path: _91id_93R8oVTxpoMMMeta?.path ?? "/sandbox/:id()",
    meta: _91id_93R8oVTxpoMMMeta || {},
    alias: _91id_93R8oVTxpoMMMeta?.alias || [],
    redirect: _91id_93R8oVTxpoMMMeta?.redirect,
    component: () => import("/app/pages/sandbox/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHMczZc2BKkMeta?.name ?? "sandbox",
    path: indexHMczZc2BKkMeta?.path ?? "/sandbox",
    meta: indexHMczZc2BKkMeta || {},
    alias: indexHMczZc2BKkMeta?.alias || [],
    redirect: indexHMczZc2BKkMeta?.redirect,
    component: () => import("/app/pages/sandbox/index.vue").then(m => m.default || m)
  },
  {
    name: primevueXhNxl3l0TxMeta?.name ?? "sandbox-primevue",
    path: primevueXhNxl3l0TxMeta?.path ?? "/sandbox/primevue",
    meta: primevueXhNxl3l0TxMeta || {},
    alias: primevueXhNxl3l0TxMeta?.alias || [],
    redirect: primevueXhNxl3l0TxMeta?.redirect,
    component: () => import("/app/pages/sandbox/primevue.vue").then(m => m.default || m)
  },
  {
    name: signupezBbZGXKU6Meta?.name ?? "signup",
    path: signupezBbZGXKU6Meta?.path ?? "/signup",
    meta: signupezBbZGXKU6Meta || {},
    alias: signupezBbZGXKU6Meta?.alias || [],
    redirect: signupezBbZGXKU6Meta?.redirect,
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: tokenHm95qqbX8gMeta?.name ?? "token",
    path: tokenHm95qqbX8gMeta?.path ?? "/token",
    meta: tokenHm95qqbX8gMeta || {},
    alias: tokenHm95qqbX8gMeta?.alias || [],
    redirect: tokenHm95qqbX8gMeta?.redirect,
    component: () => import("/app/pages/token.vue").then(m => m.default || m)
  },
  {
    name: uploadelBuHMr4HhMeta?.name ?? "upload",
    path: uploadelBuHMr4HhMeta?.path ?? "/upload",
    meta: uploadelBuHMr4HhMeta || {},
    alias: uploadelBuHMr4HhMeta?.alias || [],
    redirect: uploadelBuHMr4HhMeta?.redirect,
    component: () => import("/app/pages/upload.vue").then(m => m.default || m)
  },
  {
    name: indexnq54o81oozMeta?.name ?? "users",
    path: indexnq54o81oozMeta?.path ?? "/users",
    meta: indexnq54o81oozMeta || {},
    alias: indexnq54o81oozMeta?.alias || [],
    redirect: indexnq54o81oozMeta?.redirect,
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  }
]